@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}
body {
  height: 100vh;
  background-color: #f9f9f9;
  color: #333;
}

label {
  font-size: 20px;
  font-weight: bold;
}
button {
  font-size: 14px;
}
@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}
