.product-page-container {
  padding: 40px;
  background-color: #f0f0f5;
  color: #333;
  max-width: 1400px;
  margin: 0 auto;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.product-header {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.carousel-container {
  flex: 2;
}

.purchase-options-container {
  flex: 1;
  max-width: 300px;
  padding: 20px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.purchase-options-container h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #007bff;
  text-align: center;
}

.purchase-options-container ul {
  list-style: none;
  padding: 0;
}

.purchase-options-container li {
  border-radius: 8px;
  background: #007bff;
  margin-top: 10px;
}

.purchase-options-container a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 15px;
  font-size: 18px;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  border-radius: 8px;
}

.purchase-options-container a:hover {
  background: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

.product-info-container {
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
  color: #007bff;
}

.product-details {
  margin-top: 20px;
}

.product-details h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-details p {
  font-size: 18px;
  line-height: 1.6;
  white-space: pre-wrap;
}

.product-video {
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}

.feature-list {
  margin-top: 20px;
}

.feature-dropdown-button {
  width: 100%;
  padding: 15px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  transition: background 0.3s ease;
}

.feature-dropdown-button:hover {
  background-color: #f0f0f5;
}

.feature-description {
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  margin-top: 5px;
  padding: 10px;
  white-space: pre-wrap;
  font-size: 16px;
}

@media (max-width: 768px) {
  .product-title {
    font-size: 28px;
  }

  .product-details h3,
  .purchase-options-container h3 {
    font-size: 20px;
  }

  .product-details p,
  .feature-description,
  .purchase-options-container a {
    font-size: 14px;
  }

  .product-header {
    flex-direction: column;
  }

  .carousel-container,
  .purchase-options-container {
    flex: 1;
    width: 100%;
  }

  .purchase-options-container {
    max-width: none;
  }
}
