.carousel {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.carousel.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image-wrapper {
  width: 100%;
  height: 500px; /* Установим фиксированную высоту для изображений */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Заполнение контейнера с сохранением пропорций */
  cursor: pointer;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 10;
}

.carousel-control.left {
  left: 10px;
}

.carousel-control.right {
  right: 10px;
}

.carousel-thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.carousel-thumbnail {
  width: 60px;
  height: 60px;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  object-fit: cover; /* Обеспечивает сохранение пропорций изображения */
  border-radius: 4px; /* Добавляет скругление углов для лучшего вида */
}

.carousel-thumbnail.active,
.carousel-thumbnail:hover {
  opacity: 1;
}

.carousel-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 1001;
}
