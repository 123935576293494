.agreement-container {
  padding: 40px;
  background-color: #f9f9f9;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.agreement-container h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: bold;
}

.agreement-section {
  margin-bottom: 20px;
}

.agreement-section h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.agreement-section ul {
  list-style-type: none;
  padding: 0;
}

.agreement-section li {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 8px;
}

.agreement-footer {
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
  line-height: 1.6;
}

.agreement-footer p {
  margin-bottom: 8px;
}
