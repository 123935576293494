.category-page {
  padding: 40px;
  background-color: #f0f0f5;
  height: 100%;
  border-radius: 8px;
  max-width: 1400px;
  margin: 0 auto;
}

.category-title {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: bold;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.product-link {
  color: #333;
  text-decoration: none;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 20px;
}

.product-card {
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.product-card .background-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  transition: transform 0.5s ease;
}

.product-card:hover .background-image {
  transform: scale(1.1);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  gap: 10px;
}

.tag {
  background: rgba(0, 123, 255, 0.1);
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 16px;
  padding: 5px 10px;
  font-size: 12px;
}

.product-info {
  padding: 20px;
  text-align: center;
}

.product-name {
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0;
  color: #333;
}

.price-open {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.product-price {
  color: #ff4500;
  font-size: 18px;
  font-weight: bold;
}

.cheat-open {
  color: #fff;
  background: #007bff;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.cheat-open:hover {
  background: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

.all-filters {
  display: flex;
  align-items: center; /* Выравнивание по вертикали по центру */
  justify-content: space-between; /* Распределение пространства между элементами */
  margin-bottom: 30px;
  gap: 20px; /* Интервал между элементами */
}

.search-container {
  flex: 1; /* Занимает оставшееся пространство */
}
.search-input {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}

.filters-container {
  display: flex;
  align-items: center; /* Вертикальное выравнивание чекбоксов и текста */
  gap: 10px; /* Интервал между чекбоксами и текстом */
}

.filter-checkbox {
  font-size: 12px;
  display: flex;
  align-items: center; /* Вертикальное выравнивание чекбокса и текста */
  background: rgba(0, 123, 255, 0.1);
  border: 1px solid #007bff;
  border-radius: 8px;
  padding: 5px 10px;
  color: #007bff;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.filter-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.filter-checkbox:hover {
  background: #007bff;
  color: #fff;
}

@media (max-width: 768px) {
  .category-page {
    padding: 20px;
  }

  .products-container {
    flex-direction: column;
    align-items: center;
  }

  .product-card {
    width: 100%;
  }

  .all-filters {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .filters-container {
    justify-content: center;
  }
}
