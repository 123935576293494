.home-page {
  padding: 20px;
  background-color: #f0f0f5;
  color: #333;
  text-align: center;
}

.welcome-section {
  background: linear-gradient(135deg, #f0f0f5, #e0e0e0);
  padding: 40px;
  margin-bottom: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.welcome-section h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.welcome-section p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .welcome-section {
    padding: 20px;
  }

  .welcome-section h1 {
    font-size: 28px;
  }

  .welcome-section p {
    font-size: 16px;
  }
}
