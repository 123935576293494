.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff; /* Светлый фон */
  font-size: 16px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 20px;
  backdrop-filter: blur(8px) saturate(100%);
  -webkit-backdrop-filter: blur(8px) saturate(100%);
  border: 1px solid rgba(0, 0, 0, 0.125); /* Светлая граница */
}

.logo {
  display: flex;
  align-items: center;
  justify-self: center;
}

.logo h3 {
  font-weight: 500;
  margin-left: 10px;
  color: #000000; /* Цвет текста логотипа */
}

.logo-img {
  width: 40px;
  margin-right: 20px;
}

.navbar-nav {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  justify-self: center;
}

.nav-item {
  margin-right: 20px;
}

.nav-item:last-child {
  margin-right: 0;
}

.navbar a {
  text-decoration: none;
  color: #000000; /* Цвет текста ссылок */
  position: relative;
  transition: color 0.3s ease; /* Плавный переход цвета текста */
}

.navbar a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000; /* Цвет подчеркивания */
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.navbar a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.navbar a:hover {
  color: #007bff; /* Цвет текста при наведении */
}

@media (max-width: 768px) {
  .navbar {
    grid-template-columns: 1fr auto 1fr;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .nav-item {
    margin: 10px 0;
  }
}
