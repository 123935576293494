.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #121212;
  color: white;
  text-align: center;
}

.not-found-title {
  font-size: 96px;
  margin: 0;
}

.not-found-message {
  font-size: 24px;
  margin: 16px 0;
}

.not-found-link {
  font-size: 18px;
  color: #ff4081;
  text-decoration: none;
  border: 1px solid #ff4081;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.not-found-link:hover {
  background-color: #ff4081;
  color: #121212;
}
