.category-list-container {
  padding: 40px;
  background-color: #f0f0f5;
  height: 100%;
  border-radius: 8px;
  max-width: 1400px;
  margin: 0 auto;
}

.category-list-title {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: bold;
}

.search-input {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 30px;
  padding: 12px 20px;
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
}

.category-list-ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.category-list-li {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  width: 300px;
  height: 350px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.category-list-li:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.category-list-link {
  color: #333;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}

.category-list-link .background-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  transition: transform 0.5s ease;
}

.category-list-link .background-image-placeholder {
  width: 100%;
  height: 200px;
  background-color: #eee;
}

.category-list-li:hover .background-image {
  transform: scale(1.1);
}

.product-info {
  padding: 20px;
  text-align: center;
}

.category-name {
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0;
}

.product-count {
  color: #777;
  font-size: 16px;
}

.button-link {
  display: inline-block;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  margin-top: 10px;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.button-link:hover {
  background: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

@media (max-width: 768px) {
  .category-list-container {
    padding: 20px;
  }

  .category-list-ul {
    flex-direction: column;
    align-items: center;
  }

  .category-list-li {
    width: 100%;
    max-width: 400px;
  }
}
