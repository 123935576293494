.footer {
  background-color: #1e1e1e;
  color: #e0e0e0;
  padding: 20px;
  margin-top: 40px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin: 10px;
  max-width: 300px;
}

.footer-section ul {
  list-style: none;
}

.footer-section h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 14px;
}

.footer-section a {
  color: #007bff;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom p {
  font-size: 12px;
}
